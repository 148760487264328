import React, { useEffect } from "react";
import FieldGroup from '../Form/FieldGroup';
import CaptchaService, { CaptchaActions } from "../../services/captcha-service";
import SubmitButton from '../Form/SubmitButton';
import { SignInRequiredEmailField, RequiredPasswordField } from "../Form/InputField";
import ConditionalLoader from "../Commons/ConditionalLoader";
import signInStore from "../../stores/authentication/sign-in";
import SignIn from "../../components/sign-in/sign-in";
import { trigger } from "../../support/utilities";
import user from "../../models/user";
import common from "../../common";
import utilities from "support/utilities";

function SignInForm(props) {
	const isValid = signInStore((state) => state.valid);
	const form = signInStore((state) => state.form)
	const isLoading = signInStore((state) => state.isLoading)
	const setLoading = signInStore((state) => state.setLoading)
	const updateError = signInStore((state) => state.updateError)
	const reset = signInStore((state) => state.reset);

	function signin(token = null) {
		let {email, password} = form;
		user.authenticate(email, password, token, "WEB_SITE")
			.then(function (user) {
				SignIn.signInPromise.resolve(user);
				if (props.fromPaywall == "true") {
					trigger("metrics:gbbPWSigninSuccess");
				} else {
					trigger("metrics:signin_success", { user: user });
				}
				$("body").trigger("piano:signin-success");
				$("[js-target='email']").removeClass("error-border");
				setLoading(false)
				//reload the page
				window.location.reload();
				props.onClose();
			})
			.catch(function (data) {
				var triesLeftMsg = ". You have %s tries left.";
				var errorMessage = "You have entered an invalid email and password combination. Please try again";

				if (data.responseJSON.hasOwnProperty(common.LOGIN_TRIES_LEFT)) {
					let message = triesLeftMsg.replace("%s", data.responseJSON.tries_left)
					if (data.responseJSON.tries_left == 1) {
						message = message.replace("tries", "try")
					}
					updateError(
						"password",
						errorMessage + message,
						["password"]
					)
				} else if (data.responseJSON.hasOwnProperty(common.ACCOUNT_LOCKOUT_DURATION)) {
					let accountLockTime = utilities.getUnlockAccountTime(data.responseJSON.lockout_duration)
					updateError(
						"password",
						<span>
							Your account is blocked, please wait {accountLockTime} to unlock and try again. For immediate assistance, please contact <a href='/subscriber-help#contact-customer-service'>Customer Service</a>.
						</span>,
						["password"]
					)
				} else if (data.responseJSON.hasOwnProperty(common.USER_RESTRICTED)) {
					updateError(
						"password",
						<span>
							We're having trouble verifying your account. Please contact our <a href='/subscriber-help#contact-customer-service'>Customer Service</a> team for assistance.
						</span>,
						["password", "email"]
					);
                } else {
					updateError(
						"password",
						"You have entered an invalid email and password combination. Please try again.",
						["password", "email"]
					)
					$("[id='password']").trigger("focus");
				}
				setLoading(false)
				$("[js-target='email']").addClass("error-border");
				$("body").trigger("piano:signin-failure");
				if (props.fromPaywall == "true") {
					trigger("metrics:gbbPWSigninFail");
				} else {
					trigger("metrics:signin_fail");
				}
			});
	}

	const authenticate = (evt) => {
		evt.preventDefault();
		setLoading(true);
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					CaptchaService.executeRecaptcha(CaptchaActions.SIGN_IN_MODAL).then((token) => {
						signin(token);
					}).catch((err) => {
						console.log('err in SigninForm: ', err);
					})

				} else {
					signin();
				}
			});
	}

	useEffect(reset, []);
	return (
		<form class="form auth-content" onSubmit={authenticate} id="modalSignInForm">
			<FieldGroup withLabel="Email" for="email">
				<SignInRequiredEmailField
					id="emailAddress"
					name="email"
					js-target='email'
					{...props["email"]}
					store={signInStore}
					/>
			</FieldGroup>
			<FieldGroup withLabel="Password" for="password">
				<RequiredPasswordField
					id="password"
					name="password"
					{...props["password"]}
					store={signInStore}
					showRequirments={false}
					/>
			</FieldGroup>
			<FieldGroup>
				<ConditionalLoader isEnabled={isLoading}>
					<SubmitButton
						value="Sign In"
						enabled={isValid}
					/>
				</ConditionalLoader>
			</FieldGroup>
		</form>
	)
}

export default SignInForm
