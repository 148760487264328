const issuesJSON = {
	"year": [
		{
			"yearId": "2025",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2501",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2502",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2503",
				},
			]
		},
		{
			"yearId": "2024",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2401",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2402",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2403",
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR2404",
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR2405",
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR2406",
				},
			]
		},
		{
			"yearId": "2023",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2301",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2302",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2303",
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR2304",
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR2305",
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR2306",
				},
			]
		},
		{
			"yearId": "2022",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2201",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2202",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2203"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR2204"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR2205"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR2206"
				},
				
			]
		},
		{
			"yearId": "2021",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2101",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2102",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2103",
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR2104",
				},
				{
					"issueMonth" : "September-October",
					"issueURL": "/archive-toc/BR2105"
				},
				{
					"issueMonth" : "November-December",
					"issueURL": "/archive-toc/BR2106"
				},
			],
		},
		{
			"yearId": "2020",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR2001",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR2002",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR2003",
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR2004",
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR2005",
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR2006",
				},
			],
		},
		{
			"yearId": "2019",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1901",
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR1902",
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR1903",
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1904",
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR1905",
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR1906",
				},
			],
		},
		{
			"yearId": "2018",
			"issue": [
				{
					"issueMonth": "January–February",
					"issueURL": "/archive-toc/BR1801"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR1802"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR1803"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1804"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR1805"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR1806"
				},
			],
		},
		{
			"yearId": "2017",
			"issue": [
				{
					"issueMonth": "January–February",
					"issueURL": "/archive-toc/BR1701"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR1702"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR1703"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1704"
				},
				{
					"issueMonth": "September–October",
					"issueURL": "/archive-toc/BR1705"
				},
				{
					"issueMonth": "November–December",
					"issueURL": "/archive-toc/BR1706"
				}
			]
		},
		{
			"yearId": "2016",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1601"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1603"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1604"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1605"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1606"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1607"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1609"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1610"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1611"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1612"
				}
			]
		},
		{
			"yearId": "2015",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1501"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1503"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1504"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1505"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1506"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1507"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1509"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1510"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1511"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1512"
				}
			]
		},
		{
			"yearId": "2014",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1401"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1403"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1404"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1405"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1406"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1407"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1409"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1410"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1411"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1412"
				}
			]
		},
		{
			"yearId": "2013",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1301"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1303"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1304"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1305"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1306"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1307"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1309"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1310"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1311"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1312"
				}
			]
		},
		{
			"yearId": "2012",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1201"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1203"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1204"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1205"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1206"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1207"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1209"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1210"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1211"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1212"
				}
			]
		},
		{
			"yearId": "2011",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1101"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1103"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1104"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1105"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1106"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1107"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1109"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1110"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1111"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1112"
				}
			]
		},
		{
			"yearId": "2010",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR1001"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR1003"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR1004"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR1005"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR1006"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR1007"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR1009"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR1010"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR1011"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR1012"
				}
			]
		},
		{
			"yearId": "2009",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0901"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0902"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0903"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0904"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0905"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0906"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0907"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0907"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0909"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0910"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0911"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0912"
				}
			]
		},
		{
			"yearId": "2008",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0801"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0802"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0803"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0804"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0805"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0806"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0807"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0807"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0809"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0810"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0811"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0812"
				}
			]
		},
		{
			"yearId": "2007",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0701"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0702"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0703"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0704"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0705"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0706"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0707"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0707"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0709"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0710"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0711"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0712"
				}
			]
		},
		{
			"yearId": "2006",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0601"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0602"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0603"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0604"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0605"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0606"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0607"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0607"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0609"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0610"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0611"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0612"
				}
			]
		},
		{
			"yearId": "2005",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0501"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0502"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0503"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0504"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0505"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0506"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0507"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0507"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0509"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0510"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0511"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0512"
				}
			]
		},
		{
			"yearId": "2004",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0401"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0402"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0403"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0404"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0405"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0406"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0407"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0407"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0409"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0410"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0411"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0412"
				}
			]
		},
		{
			"yearId": "2003",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0301"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0302"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0303"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0304"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0305"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0306"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0307"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0308"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0309"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0310"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0311"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0312"
				}
			]
		},
		{
			"yearId": "2002",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0201"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0202"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0203"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0204"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0205"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0206"
				},
				{
					"issueMonth": "July",
					"issueURL": "/archive-toc/BR0207"
				},
				{
					"issueMonth": "August",
					"issueURL": "/archive-toc/BR0208"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0209"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0210"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0211"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0212"
				}
			]
		},
		{
			"yearId": "2001",
			"issue": [
				{
					"issueMonth": "January",
					"issueURL": "/archive-toc/BR0101"
				},
				{
					"issueMonth": "February",
					"issueURL": "/archive-toc/BR0102"
				},
				{
					"issueMonth": "March",
					"issueURL": "/archive-toc/BR0103"
				},
				{
					"issueMonth": "April",
					"issueURL": "/archive-toc/BR0104"
				},
				{
					"issueMonth": "May",
					"issueURL": "/archive-toc/BR0105"
				},
				{
					"issueMonth": "June",
					"issueURL": "/archive-toc/BR0106"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR0107"
				},
				{
					"issueMonth": "September",
					"issueURL": "/archive-toc/BR0108"
				},
				{
					"issueMonth": "October",
					"issueURL": "/archive-toc/BR0109"
				},
				{
					"issueMonth": "November",
					"issueURL": "/archive-toc/BR0110"
				},
				{
					"issueMonth": "December",
					"issueURL": "/archive-toc/BR0111"
				}
			]
		},
		{
			"yearId": "2000",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/BR001"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/BR002"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/BR003"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/BR004"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/BR005"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/BR006"
				}
			]
		},
		{
			"yearId": "1999",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3991"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3992"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3993"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3994"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3995"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3996"
				}
			]
		},
		{
			"yearId": "1998",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3981"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3982"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3983"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3984"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3985"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3986"
				}
			]
		},
		{
			"yearId": "1997",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3971"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3972"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3973"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3974"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3975"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3976"
				}
			]
		},
		{
			"yearId": "1996",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3961"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3962"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3963"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3964"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3965"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3966"
				}
			]
		},
		{
			"yearId": "1995",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3951"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3952"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3953"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3954"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3955"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3956"
				}
			]
		},
		{
			"yearId": "1994",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3941"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3942"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3943"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3944"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3945"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3946"
				}
			]
		},
		{
			"yearId": "1993",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3931"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3932"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3933"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3934"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3935"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3936"
				}
			]
		},
		{
			"yearId": "1992",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3921"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3922"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3923"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3924"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3925"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3926"
				}
			]
		},
		{
			"yearId": "1991",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3911"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3912"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3913"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3914"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3915"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3916"
				}
			]
		},
		{
			"yearId": "1990",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3901"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3902"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3903"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3904"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3905"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3906"
				}
			]
		},
		{
			"yearId": "1989",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3891"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3892"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3893"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3894"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3895"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3896"
				}
			]
		},
		{
			"yearId": "1988",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3881"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3882"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3883"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3884"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3885"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3886"
				}
			]
		},
		{
			"yearId": "1987",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3871"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3872"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3873"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3874"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3875"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3876"
				}
			]
		},
		{
			"yearId": "1986",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3861"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3862"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3863"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3864"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3865"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3866"
				}
			]
		},
		{
			"yearId": "1985",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3851"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3852"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3853"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3854"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3855"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3856"
				}
			]
		},
		{
			"yearId": "1984",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3841"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3842"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3843"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3844"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3845"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3846"
				}
			]
		},
		{
			"yearId": "1983",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3831"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3832"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3833"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3834"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3835"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3836"
				}
			]
		},
		{
			"yearId": "1982",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3821"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3822"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3823"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3824"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3825"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3826"
				}
			]
		},
		{
			"yearId": "1981",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3811"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3812"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3813"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3814"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3815"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3816"
				}
			]
		},
		{
			"yearId": "1980",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3801"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3802"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3803"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3804"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3805"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3806"
				}
			]
		},
		{
			"yearId": "1979",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3791"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3792"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3793"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3794"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3795"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3796"
				}
			]
		},
		{
			"yearId": "1978",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3781"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3782"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3783"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3784"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3785"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3786"
				}
			]
		},
		{
			"yearId": "1977",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3771"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3772"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3773"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3774"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3775"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3776"
				}
			]
		},
		{
			"yearId": "1976",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3761"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3762"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3763"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3764"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3765"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3766"
				}
			]
		},
		{
			"yearId": "1975",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3751"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3752"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3753"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3754"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3755"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3756"
				}
			]
		},
		{
			"yearId": "1974",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3741"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3742"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3743"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3744"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3745"
				}
			]
		},
		{
			"yearId": "1973",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3731"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3732"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3733"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3734"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3735"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3736"
				}
			]
		},
		{
			"yearId": "1972",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3721"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3722"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3724"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3725"
				}
			]
		},
		{
			"yearId": "1971",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3711"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3712"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3714"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3715"
				}
			]
		},
		{
			"yearId": "1970",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3701"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3703"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3704"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3705"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3706"
				}
			]
		},
		{
			"yearId": "1969",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3691"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3692"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3693"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3694"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3696"
				}
			]
		},
		{
			"yearId": "1968",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3681"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3682"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3684"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3685"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3686"
				}
			]
		},
		{
			"yearId": "1967",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3671"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3672"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3673"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3676"
				}
			]
		},
		{
			"yearId": "1966",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3661"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3662"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3664"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3665"
				}
			]
		},
		{
			"yearId": "1965",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3651"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3653"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3654"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3655"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3656"
				}
			]
		},
		{
			"yearId": "1964",
			"issue": [
				{
					"issueMonth": "January-February",
					"issueURL": "/archive-toc/3641"
				},
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3642"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3643"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3644"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3645"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3646"
				}
			]
		},
		{
			"yearId": "1963",
			"issue": [
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3633"
				},
				{
					"issueMonth": "July-August",
					"issueURL": "/archive-toc/3634"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3635"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3636"
				}
			]
		},
		{
			"yearId": "1962",
			"issue": [
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3622"
				},
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3623"
				}
			]
		},
		{
			"yearId": "1961",
			"issue": [
				{
					"issueMonth": "March-April",
					"issueURL": "/archive-toc/3612"
				},
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3615"
				}
			]
		},
		{
			"yearId": "1960",
			"issue": [
				{
					"issueMonth": "May-June",
					"issueURL": "/archive-toc/3603"
				},
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3606"
				}
			]
		},
		{
			"yearId": "1958",
			"issue": [
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3586"
				}
			]
		},
		{
			"yearId": "1957",
			"issue": [
				{
					"issueMonth": "September-October",
					"issueURL": "/archive-toc/3575"
				}
			]
		},
		{
			"yearId": "1956",
			"issue": [
				{
					"issueMonth": "November-December",
					"issueURL": "/archive-toc/3566"
				}
			]
		}
	]
};

var getIssues = function() {
	return issuesJSON;
};

export default {
	getIssues: getIssues
};
