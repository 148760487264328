/**
 * @module components/sign-in/sign-in
 * @requires module:jquery
 * @requires module:model/user
 * @requires module:services/state
 */

// jquery
import $ from "jquery";

// user model
import user from "../../models/user";

// state service
import state from "../../services/state";

// Component mixin.
import mixinComponent from "../../mixins/component";

import CaptchaService, {CaptchaActions} from "../../services/captcha-service"

import common from "../../common"
import utilities from "support/utilities";

// global authentication promise
var signInPromise = $.Deferred();

// constructor
export default function SignIn($el) {
	this.initializeAsComponent($el);
}

// events
SignIn.prototype = {
	events: {
		"click [js-target='submit-sign-in']": "signIn",
	},

	signInHandler(token) {
		// pull the username and password from the form
		var username = this.$el.find("input[js-target='username-input']").val();
		var password = this.$el.find("input[js-target='password-input']").val();
		let signInButton = this.$el.find("button[js-target='submit-sign-in']");
	
		// set the pending state
		state.set("guest:sign-in:pending");
		// set success text on button prior to displaying, after auth callback it takes 1 second to update on DOM
		signInButton.text("Success, you will now be redirected");
	
		// submit the authentication
		user
			.authenticate(username, password, token, "WEB_SITE")
			.then(function(user) {
				// resolve the sign in promise
				signInPromise.resolve(user);
				window.location.reload();
			}, function(data) {
				// reset the state to failed and reset button text
				signInButton.text("Sign In");
				state.set("guest:sign-in:fail");
				let error = common.ERROR_USER_PASSWORD.substring(0,77);;
				if (data.responseJSON.hasOwnProperty(common.LOGIN_TRIES_LEFT)) {
					error = common.ERROR_USER_PASSWORD.replace("%s", data.responseJSON.tries_left);
					if(data.responseJSON.tries_left === 1) error = error.replace("tries", "try");
				} else if (data.responseJSON.hasOwnProperty(common.ACCOUNT_LOCKOUT_DURATION)) {
					error = common.ERROR_ACCOUNT_LOCK.replace("%s", utilities.getUnlockAccountTime(data.responseJSON.lockout_duration));
				} else if (data.responseJSON.hasOwnProperty(common.USER_RESTRICTED)) {
					error = common.RESTRICTED_USER_MESSAGE;
				}
				document.getElementById("error-message").innerHTML = error;
				document.getElementById("login-email").focus();
				utilities.trigger("metrics:signin_fail");
			});
		$("body").trigger("form-interaction", ["sign-in", "Form Submission"]);
	},

	signIn: function(e) {
		e.preventDefault();
		e.stopPropagation();
		
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					CaptchaService.executeRecaptcha(CaptchaActions.SIGN_IN_SUB).then((token) => {
						const captchaError = document.getElementById("captchaError");
						if (!token) {
							captchaError.classList.remove("is-hidden");
							return null;
						}
						this.signInHandler(token);
					});
				} else {
					this.signInHandler();
				}
			});
	}
};

// Decorate this constructor as a component.
mixinComponent(SignIn, "sign-in");

// expose the authentication promise
SignIn.signInPromise = signInPromise;

// export the component
